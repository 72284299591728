/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  Icon,
  Grid,
  makeStyles,
  Typography,
  Link,
  CardMedia,
} from '@material-ui/core';
import React from 'react';
import { COLORS } from '../../../plTheme';
import WarningIcon from '@material-ui/icons/Warning';
import CloudOffTwoToneIcon from '@material-ui/icons/CloudOffTwoTone';
import { _t_ } from '../../../utils/translation/translation';
import { useHistory } from 'react-router';
import Logo from '../../../assets/HeaderIcons/PeopleLens_Logo.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#0B69FF',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    backgroundColor: COLORS.HOMEPAGE_BACKGROUND,
    height: 450,
    width: '42%',
    padding: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  mainTitle: {
    fontSize: 20,
    fontWeight: 400,
    marginTop: 18,
  },
  subTitle: {
    fontSize: 18,
    fontWeight: 300,
    marginTop: 18,
  },
}));

const AccessDenied: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid className={classes.root}>
      <Grid className={classes.container}>
        <CardMedia
          style={{ height: 33, width: 160, marginBottom: 25 }}
          // title={title}
          image={Logo}
        />
        <Icon
          component={WarningIcon}
          // component={CloudOffTwoToneIcon}
          style={{ color: '#e99701', width: 45, height: 45 }}
        />
        <Typography className={`${classes.mainTitle}`}>
          {_t_('Access to this Application is blocked')}
        </Typography>
        <Typography className={`${classes.subTitle}`}>
          {_t_('Please contact your administrator.')}
        </Typography>
        <Typography className={`${classes.subTitle}`}>
          <Link
            href="#"
            style={{ color: '#0B69FF', cursor: 'pointer' }}
            onClick={() => {
              history.push('/logout');
            }}
          >
            Back to Login
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AccessDenied;
