import React, { useEffect } from 'react';
import Rating from '@material-ui/lab/Rating';
import {
  makeStyles,
  withStyles,
  useMediaQuery,
  CardMedia,
} from '@material-ui/core';
import Stack from '@mui/material/Stack';

import { styled } from '@material-ui/core/styles';

import checkedIcon from '../../../assets/CompetencieIcons/checkedIcon.png';
import uncheckedIcon from '../../../assets/CompetencieIcons/checkedIcon-outline.png';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CircleIcon from '@mui/icons-material/Circle';
import './index.css';

interface RatingChartProps {
  value?: number | null;
  indicator: string;
}

const CustomRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-root': {
    fontSize: '1.6rem !important',
  },
  '& .MuiRating-iconFilled': {
    color: 'rgb(91, 143, 249)', // Change this color to your desired star color
  },
}));

const RatingChart: React.FC<RatingChartProps> = ({
  value,
  indicator,
}: RatingChartProps) => {
  // const useStyles = makeStyles({
  //   quantityRoot: {
  //     '& .MuiRating-iconFilled:hover': {
  //       backgroundColor: 'transparent',
  //     },
  //   },
  // });

  // const classes = useStyles();

  return (
    <Stack spacing={1}>
      <CustomRating
        name="circular-rating"
        defaultValue={value!}
        precision={0.01}
        readOnly
        emptyIcon={<RadioButtonUncheckedIcon />}
        icon={<CircleIcon style={{ color: '#2E6FF2' }} />}
      />
    </Stack>
  );
};

export default RatingChart;
